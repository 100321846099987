import { formatValue } from '~/services/format'
import {
  compareValuesAlphabetically,
  getFormattedDimensionValue
} from '~/services/analytics'
import {
  getCubeFromMeasureOrDimensionName,
  getDimensionFromTitleAndCube,
  getDimensionTranslation,
  getMeasureFromTitle
} from '~/services/explore'
import { MemberFormat, type Dimension, type Measure } from '~/types/cube'
import type { DimensionValue, DimensionWithValue } from '~/types/analytics'
import type { ResultSet, ResultSetItem } from '~/types/query'
import type { TableCell, TableData } from '~/types/table'

export const getCustomTableData = (
  resultSet: ResultSet,
  measureTitle: string,
  rowDimensionTitles: string[],
  columnDimensionTitles: string[],
  defaultColumnValues: string[] | null,
  displayValuesAsPercent: boolean = false,
  displayTotal: boolean = false
): TableData => {
  const rawResultSetItems = resultSet.results
    .map(results => results.data)
    .flat()

  const measure = getMeasureFromTitle(measureTitle)
  const filteredResultsData = rawResultSetItems.filter(
    resultSetItem => resultSetItem[measure.name] !== null
  )

  const cube = getCubeFromMeasureOrDimensionName(measure.name)
  const rowDimensions = rowDimensionTitles.map(
    dimensionTitle => getDimensionFromTitleAndCube(dimensionTitle, cube)!
  )

  const columnDimensions = columnDimensionTitles.map(
    dimensionTitle => getDimensionFromTitleAndCube(dimensionTitle, cube)!
  )
  const dimensions = [...columnDimensions, ...rowDimensions]

  // If results data is empty we add one fake empty data to create an empty table
  const resultsData =
    filteredResultsData.length > 0
      ? filteredResultsData
      : [
          [...dimensions, measure].reduce(
            (fakeResultSet, member) => ({
              ...fakeResultSet,
              [member.name]: null
            }),
            {}
          )
        ]

  const columnValues =
    columnDimensions.length > 0
      ? getColumnValues(columnDimensions[0], resultsData, defaultColumnValues)
      : [undefined]

  const headerRows =
    columnDimensions.length > 0
      ? [
          [
            ...rowDimensions.map(dimension =>
              getTableCell(getDimensionTranslation(dimension), [], true)
            ),
            ...columnValues.map(value => {
              const formattedValue = getFormattedDimensionValue(
                value,
                columnDimensions[0]!
              )

              return getTableCell(
                formattedValue,
                [
                  [
                    columnDimensions[0]!,
                    value as Exclude<typeof value, undefined>
                  ]
                ],
                true,
                {
                  initialValue: formattedValue
                }
              )
            })
          ]
        ]
      : []

  const bodyRows = [] as TableCell[][]

  getRowsWithDimensionValues(resultsData, rowDimensions).forEach(
    rowWithDimensionValues => {
      const bodyRow = [] as TableCell[]

      if (rowDimensions.length > 0)
        rowDimensions.forEach((rowDimension, rowDimensionIndex) => {
          const formattedDimensionValue = getFormattedDimensionValue(
            rowWithDimensionValues[rowDimensionIndex],
            rowDimension
          )

          bodyRow.push(
            getTableCell(
              formattedDimensionValue,
              [[rowDimension, rowWithDimensionValues[rowDimensionIndex]!]],
              true,
              {
                initialValue: formattedDimensionValue
              }
            )
          )
        })

      columnValues.forEach(columnValue => {
        const cellDimensionValues = [
          ...(columnValue !== undefined ? [columnValue] : []),
          ...rowWithDimensionValues
        ]

        const matchingResultSetItem = getMatchingResultSetItem(
          cellDimensionValues,
          resultsData,
          dimensions
        )

        const dimensionsWithValue = getDimensionsWithValue(
          cellDimensionValues,
          dimensions
        )

        const value = displayValuesAsPercent
          ? getMeasurePercentValue(
              matchingResultSetItem,
              measure,
              columnDimensions,
              resultsData
            )
          : getMeasureValue(matchingResultSetItem, measure)

        bodyRow.push(
          getTableCell(value, dimensionsWithValue, false, {
            initialValue: value.toString()
          })
        )
      })
      bodyRows.push(bodyRow)
    }
  )

  if (displayTotal) {
    const { $t } = useNuxtApp()

    const total = columnValues.map(() => 0)

    getRowsWithDimensionValues(resultsData, rowDimensions).forEach(
      rowWithDimensionValues => {
        columnValues.forEach((columnValue, i) => {
          const cellDimensionValues = [
            ...(columnValue !== undefined ? [columnValue] : []),
            ...rowWithDimensionValues
          ]

          const matchingResultSetItem = getMatchingResultSetItem(
            cellDimensionValues,
            resultsData,
            dimensions
          )

          total[i]! += matchingResultSetItem?.[measure.name]
            ? parseFloat(matchingResultSetItem[measure.name] as string)
            : 0
        })
      }
    )

    const totalRow = [
      getTableCell($t('explore.options.total'), [], true, {
        colSpan: rowDimensions.length.toString()
      }),
      ...total.map(value => {
        const formattedValue = displayValuesAsPercent
          ? formatValue(100, MemberFormat.PERCENT, false)
          : formatValue(value, measure.meta.format, false)

        return getTableCell(formattedValue, [], false)
      })
    ]

    return { headerRows, bodyRows, totalRow }
  }

  return { headerRows, bodyRows, totalRow: null }
}

export const getTableCell = (
  content: string | null = null,
  dimensionsWithValue: DimensionWithValue[] = [],
  isHeader: boolean = false,
  attributes:
    | { colSpan?: string; initialValue?: string }
    | undefined = undefined
): TableCell => {
  return { isHeader, content, dimensionsWithValue, attributes }
}

const getColumnValues = (
  columnDimension: Dimension | undefined,
  resultsData: ResultSetItem[],
  defaultColumnValues: string[] | null
) => {
  if (defaultColumnValues) return defaultColumnValues
  if (!columnDimension) return []

  const uniqueColumnValues = Array.from(
    new Set(
      resultsData.map(resultSetItem =>
        getDimensionValueFromResultSetItem(resultSetItem, columnDimension!)
      )
    )
  )

  return uniqueColumnValues.toSorted(compareValuesAlphabetically)
}

const getDimensionValueFromResultSetItem = (
  resultSetItem: ResultSetItem,
  dimension: Dimension
): DimensionValue => {
  return resultSetItem[dimension.name]?.toString() ?? null
}

const getRowsWithDimensionValues = (
  resultsData: ResultSetItem[],
  rowDimensions: Dimension[]
) => {
  const allDimensionValues = resultsData.map(resultSetItem =>
    rowDimensions.map(dimension =>
      getDimensionValueFromResultSetItem(resultSetItem, dimension)
    )
  )

  const uniqueRowValues = allDimensionValues.reduce(
    (uniqueRowValues, curentDimensionRow) => [
      ...uniqueRowValues,
      ...(uniqueRowValues.some(dimensionRow =>
        dimensionRow.every(
          (value, index) => value === curentDimensionRow[index]
        )
      )
        ? []
        : [curentDimensionRow])
    ],
    [] as DimensionValue[][]
  )

  return rowDimensions.reduce(
    (sortedDimensionValues, _, dimensionIndex) =>
      sortedDimensionValues.toSorted((dimensionRow1, dimensionRow2) =>
        compareValuesAlphabetically(
          dimensionRow1[rowDimensions.length - 1 - dimensionIndex]!,
          dimensionRow2[rowDimensions.length - 1 - dimensionIndex]!
        )
      ),
    uniqueRowValues as DimensionValue[][]
  )
}

const getMatchingResultSetItem = (
  cellDimensionValues: DimensionValue[],
  resultData: ResultSetItem[],
  dimensions: Dimension[]
) => {
  return resultData.find(resultSetItem =>
    dimensions.every(
      (dimension, dimensionIndex) =>
        getDimensionValueFromResultSetItem(resultSetItem, dimension) ===
        cellDimensionValues[dimensionIndex]
    )
  )
}

const getDimensionsWithValue = (
  cellDimensionValues: DimensionValue[],
  dimensions: Dimension[]
) => {
  return dimensions.map(
    (dimension, index) =>
      [dimension, cellDimensionValues[index]] as DimensionWithValue
  )
}

const getMeasureValue = (
  matchingResultSetItem: ResultSetItem | undefined,
  measure: Measure
) => {
  return formatValue(
    matchingResultSetItem ? matchingResultSetItem[measure.name]! : null,
    measure.meta.format,
    false
  )
}

const getMeasurePercentValue = (
  matchingResultSetItem: ResultSetItem | undefined,
  measure: Measure,
  columnDimensions: Dimension[],
  resultsData: ResultSetItem[]
) => {
  const resultsDataTotal = resultsData.reduce(
    (total, item) =>
      isNaN(item[measure.name] as number)
        ? total
        : total + (item[measure.name] as number),
    0
  )

  const resultsDataTotalByColumnValues =
    columnDimensions.length > 0
      ? resultsData.reduce<{ [key: string]: number }>((total, item) => {
          return isNaN(item[measure.name] as number)
            ? total
            : {
                ...total,
                [item[columnDimensions[0]!.name] as string]:
                  (total[item[columnDimensions[0]!.name] as string] ?? 0) +
                  (item[measure.name] as number)
              }
        }, {})
      : undefined

  const total =
    columnDimensions.length > 0
      ? resultsDataTotalByColumnValues![
          matchingResultSetItem?.[columnDimensions[0]!.name] as string
        ]
      : resultsDataTotal

  return formatValue(
    matchingResultSetItem && total
      ? ((matchingResultSetItem[measure.name] as number) / total) * 100
      : 0,
    MemberFormat.PERCENT,
    false
  )
}
